
export default {
  name: 'ButtonIndex',

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    type: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      disabledButton: false,
    }
  },

  watch: {
    loading() {
      this.initState();
    },

    disabled() {
      this.initState();
    },
  },

  created() {
    this.initState();
  },



  methods: {
    initState() {
      if(this.loading) {
        this.disabledButton = true;
      } else {
          this.disabledButton = this.disabled;
      }
    },

    handleClick() {
      if (this.loading || this.disabled){
      return;
    }

    this.$emit('click');
    },
  },
}
