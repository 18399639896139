import {getCookie} from "@/helpers";

export default {
  computed: {
    serverLogin() {
      return process.env.loginUrl
    },

    loginURL() {
      return `${this.serverLogin}/login${getCookie('query')}`
    },

    registerURL() {
      return `${this.serverLogin}/register${getCookie('query')}`
    }
  },
}
