
import AppMixin from '~/mixins/App'
import envMixin from "@/mixins/envMixin";

export default {
  name: 'HeaderIndex',
  mixins: [AppMixin, envMixin],
  data() {
    return {
      homePath: '/',
    }
  },
  watch: {
    $route(currentRoute) {
      // to get back to parent route when the page is inside /videos or /company-careers
      this.homePath = this.getPath(currentRoute)
    },
  },

  mounted() {
    this.homePath = this.getPath(this.$route)
  },

  methods: {
    goTo(url) {
      if (!url) {
        return
      }

      window.open(url, '_blank')
    },

    getPath(currentRoute) {
      let path = '/'

      const arr = ['company-careers', 'creative-video']
      arr.forEach((url) => {
        if (currentRoute.path.includes(url)) {
          path = `/${url}`
        }
      })
      return path
    },
  },
}
